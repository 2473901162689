import moment from 'moment';
import 'slick-carousel';

jQuery( document ).ready(function($) {
    $.ajax( {
        url: 'https://rest.bandsintown.com/artists/Meghan%20Patrick/events?app_id=45PRESS_meghanp',
        method: 'GET',
        dataType: 'json',
        xhrFields: {
            withCredentials: false
        },
        error: () => {
        },
        success: data => {
            const events = $( '#events' );
            let html = '';
            if ( data.length ) {
                for ( let event of data ) {
                    const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
                    html += '<div class="event" data-bit-id="' + event.id + '">';
                    html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM DD' ) + '</div>';
                    html += '<div class="event-venue">' + event.venue.name + '</div>';
                    html += '<div class="event-location">' + event.venue.city + ', ' + event.venue.region + '</div>';
                    if (event.offers.length){
                        html += '<a href="' + event.offers[0].url + '" target="_blank" class="btn btn-white">Tickets</a>';
                    } else{
                        html += '<a href="' + event.url + '" target="_blank" class="btn btn-white">Tickets</a>';
                    }
                    html += '</div>';
                }
                events.html( html );
            } else {
                events.html( 'No upcoming events.' );
            }
        }
    });


    
    $('#video-thumbs').slick({
        prevArrow: '<button type="button" class="slick-prev"><i aria-label="prev" class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i aria-label="next" class="fas fa-chevron-right"></i></button>',
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1
    });
    $('.music-slider').slick({
        prevArrow: '<button type="button" class="slick-prev"><i aria-label="prev" class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i aria-label="next" class="fas fa-chevron-right"></i></button>',
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            
            {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
        ]
    });
    $('.merch-slider').slick({
        prevArrow: '<button type="button" class="slick-prev"><i aria-label="prev" class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i aria-label="next" class="fas fa-chevron-right"></i></button>',
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
        ]
    });

    $('#video-thumbs a').on('click', function(e) {
        const VIDEO    = $(this).data('video-id');
        const $FEATURE = $('#featured-video').find('iframe');
        const $TITLE = $('#featured-video').find('span');
        e.preventDefault();
        $FEATURE.attr('src', `https://www.youtube.com/embed/${VIDEO}?autoplay=1`);
        $TITLE.html($(this).attr('data-title'));
        $('body, html').stop().animate({
            scrollTop: $FEATURE.offset().top - 30
        }, 300);
    });

    $('.scroll').on('click', function(e) {
        const TARGET = $(this).attr('href'); 
    
        e.preventDefault();
    
        $('body, html').animate({
            scrollTop: $(TARGET).offset().top - 100
        });
    });
    
});